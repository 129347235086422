import React, {useEffect, useState}  from 'react';
import { Link } from 'gatsby';

const Blog = (props) => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
    let pageNumbers = [];

    useEffect(() => {
        setPosts(props.posts);
        setPostsPerPage(props.postsPerPage)
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    for (let i=1; i<=Math.ceil(posts.length / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    const paginate = (event, pageNumber) => {
        event.preventDefault()
        event.stopPropagation()
        setCurrentPage(pageNumber);
    }

    const renderPostLink = (post) => {
        let link = <></>;
        switch (post.postType) {
          case "newsletter":
          case "curated":
            const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
            const linkUrl = (post.content.match(linkRx)[2]);
            link = <Link href={linkUrl} target="_blank" rel="noopener noreferrer">{post.title}</Link>
            break;
          default:
            link = <Link to={`/posts/${post.id}`}>{post.title}</Link>
            break;
          }
        return link;
    }

    return (
        <>
            <ul className="list-group mb-4">
                {currentPosts.map(post => (
                    <li key={post.id} className="list-group-item">
                        <h4>{renderPostLink(post)}</h4>
                        <p><i className="bi bi-person-circle text-primary"></i> By {post.author}, {new Date(post.date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                        { <span dangerouslySetInnerHTML={{ __html: post.briefDescription }} /> }
                    </li>
                ))}
            </ul>
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        number == currentPage ?
                            <li key={number} className="page-item active"><a className="page-link">{number}</a></li>
                            :
                            <li key={number} className="page-item">
                                <a onClick={(e) => paginate(e, number)} href="!#" className="page-link">
                                    {number}
                                </a>
                            </li>
                    ))}
                </ul>
            </nav>
        </>
    );
  }

export default Blog;
