import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Home from '../components/Home';
import Head from "../components/Head";
import Layout from "../components/Layout";

const Index = () => {

  const qraphQLdata = useStaticQuery(graphql`
    query {
      sitePage {
        pageContext
      }
    }
  `);
  const toddPressData = (qraphQLdata)? qraphQLdata.sitePage.pageContext.allPosts : [];

    return (
        <Layout>
          <Head pageTitle="EyeDog.US - Wayfinding as a Service for Healthcare" title="EyeDog.US - Wayfinding as a Service for Healthcare" path="/" description="Eyedog.US Pairs Clear and Concise Digital Wayfinding Technology With Expert Implementation To Provide A Seamless Indoor Navigation Experience." imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/Eyedog.US-Digital-Wayfinding.jpg" type="website" datePublished="02/17/2022" />
          <Home toddPressData={toddPressData}/>
        </Layout>
    )
}

export default Index;

