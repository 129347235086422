import React, {useEffect} from 'react';
import { Link } from "gatsby";
import * as BigPicture from "bigpicture";
import Blog from './Blog'; 
import PatientJouney01 from "../assets/img/patient-journey-1.png";
import PatientJouney02 from "../assets/img/patient-journey-2.png";
import PatientJouney03 from "../assets/img/patient-journey-3.png";
import PatientJouney04 from "../assets/img/patient-journey-4.png";
import PatientJouney05 from "../assets/img/patient-journey-5.png";
import KayVia from "../assets/img/aboutus-kay-via.png";
import JeffFisher from "../assets/img/aboutus-jeff-fisher.png";
import JohnSkinner from "../assets/img/aboutus-john-skinner.png";
import IconFeaturesEmpathetic from "../assets/img/icon-empathetic.svg";
import IconFeaturesEffective from "../assets/img/icon-effective.svg";
import IconFeaturesAgile from "../assets/img/icon-agile.svg";
import IconFeaturesAdoptable from "../assets/img/icon-adoptable.svg";
import IllusEyedogAdvantage2 from "../assets/img/illus-eyedog-advantage2.jpg";
import IconScientificallyProven from "../assets/img/icon-scientifically-proven.svg";
import IconPhotosNotMaps from "../assets/img/icon-photos-not-maps.svg";
import IconVisualization from "../assets/img/icon-visualization.svg";
import IconReducesStress from "../assets/img/icon-reduces-stress.svg";
import IllusEyedogAdvantage from "../assets/img/illus-eyedog-advantage.jpg";
import QuotationIcon from "../assets/img/icon-quotation.svg";




const Home = (props) => {

    useEffect(() => {
        /*
		document.getElementById("but_fullScreenVid").addEventListener('click',      
			(event) => {
				event.preventDefault()
				event.stopPropagation()
                const bigPic = new BigPicture({
                    el: event.target,
                    ytSrc: "ZrmS2Q0aGvM",
                    dimensions: [1226, 900],
                })
		});
        */

        if (props.toddPressData?.length > 0) {
            const topPosts = props.toddPressData.slice(0, 1);
            if (topPosts) {
                const latestPost = topPosts[0];
                const todayDate = new Date();
                const featureBeginDate = new Date(latestPost.date);
                let featureEndDate = new Date(latestPost.date);
                featureEndDate = new Date(featureEndDate.setDate(featureEndDate.getDate() + 9));
                if (todayDate >= featureBeginDate && todayDate <= featureEndDate) {
                    let postHeader = "";
                    let readMoreButton = "";
                    let linkUrl = "";
                    const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
                    // new press release or post?
                    switch(latestPost.postType) {
                        case "press":
                            postHeader = `<h5 class="m-0"><i class="bi bi-broadcast-pin"></i> <em>Latest News</em></h5>`;
                            readMoreButton = `<a class="btn btn-outline-light btn-lg mt-3" href="/posts/${latestPost.id}">Read More...</a>`;
                            break;
                        case "newsletter":
                            postHeader = `<h5 class="m-0"><i class="bi bi-newspaper"></i> <em>Latest Newsletter</em></h5>`;
                            linkUrl = (latestPost.content.match(linkRx)[2]);
                            readMoreButton = `<a href=${linkUrl} class="btn btn-outline-light btn-lg mt-3" target="_blank" rel="noopener noreferrer">Read More...</a>`;
                            break;
                        case "curated":
                            postHeader = `<h5 class="m-0"><i class="bi bi-chat-right-text"></i> <em>New Post</em></h5>`;
                            linkUrl = (latestPost.content.match(linkRx)[2]);
                            readMoreButton = `<a href=${linkUrl} class="btn btn-outline-light btn-lg mt-3" target="_blank" rel="noopener noreferrer">Read More...</a>`;
                            break;
                        default:
                            postHeader = `<h5 class="m-0"><i class="bi bi-chat-right-text"></i> <em>New Post</em></h5>`;
                            readMoreButton = `<a class="btn btn-outline-light btn-lg mt-3" href="/posts/${latestPost.id}">Read More...</a>`;
                      }

                    //const postHeader = (latestPost.postType === "press")? `<h5 class="m-0"><i class="bi bi-broadcast-pin"></i> <em>Latest News</em></h5>`:`<h5 class="m-0"><i class="bi bi-chat-right-text"></i> <em>New Post</em></h5>`;
                    const postPlaceHolder = document.getElementById("div_latestPressPlaceholder");
                    postPlaceHolder.innerHTML = `
                        <div class="text-center mb-3" data-aos="fade-down" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back"><div style="background:rgba(161,92,0,0.25);" class="badge rounded-pill text-center text-white px-3">${postHeader}</div></div>
                        <h2 class="text-center text-white mt-0" data-aos="zoom-in" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">${latestPost.title}</h2>
                        <hr class="divider bg-white" />
                        <span class="text-white" data-aos="zoom-in" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">${latestPost.briefDescription}</span>
                        <p class="text-center" data-aos="fade-up" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">${readMoreButton}</p>
                    `;
                    const postSection = document.getElementById("latest-press");
                    postSection.classList.remove("d-none");
                }
            }
        }
    }, []);

    return (
        <>

            {/* Masthead */}
            <header className="masthead min-vh-100" id="pagetop">
                <div className="container px-4 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-end">
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-9 col-xs-9 pb-3" data-aos="fade-up" data-aos-duration="1000">
                            <h1 className="text-white font-weight-bold">Wayfinding as a Service<sup><span style={{fontSize:14}}>SM</span></sup> for Healthcare</h1>
                            <hr className="divider" />
                            <p className="text-white mb-4">Eyedog uses landmark photos to provide clear indoor pedestrian directions. Our scientifically proven technology, paired with expert implementation and adoption services, promise better, faster, and more affordable wayfinding tailored to your health system's unique needs.</p>
                            <Link className="btn btn-outline-light btn-xl" to="/meetnow">Schedule your Demo</Link>
                        </div>
                    </div>
                </div>
            </header>


            {/* Latest Press Announcement */}
            <section className="page-section bg-primary clearfix d-none" id="latest-press">
                <div className="container">
                    <div id="div_latestPressPlaceholder"></div>
                </div>
            </section>


            {/* Patient Journey */}
            <section className="page-section clearfix" id="patient-journey">
                <div className="container">

                    <h2 className="text-center mt-0">The Patient Journey</h2>
                    <p className="text-center">Eyedog makes the Patient Journey from home to appointment easy! Start your route from the comfort of your own home, for easy-to-follow directions the whole way.</p>
                    <hr className="divider" />

                    {/* 5 panels only visible on large screens */}
                    <div className="d-none d-md-block d-lg-block">
                        <div className="row flex-nowrap">
                            <div className="col pat-journey text-center" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
                                <img src={PatientJouney01} className="img-fluid mx-auto" alt="..." />
                                <p className="small">Start at home with appointment reminders.</p>
                            </div>
                            <div className="col pat-journey text-center" data-aos="fade-down" data-aos-duration="500" data-aos-once="true">
                                <img src={PatientJouney02} className="img-fluid mx-auto" alt="..." />
                                <p className="small">Personalized parking &#38; entrance options chosen by the patient</p>
                            </div>
                            <div className="col pat-journey text-center" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
                                <img src={PatientJouney03} className="img-fluid mx-auto" alt="..." />
                                <p className="small">Physical signage available corresponds to key POIs</p>
                            </div>
                            <div className="col pat-journey text-center" data-aos="fade-down" data-aos-duration="500" data-aos-once="true">
                                <img src={PatientJouney04} className="img-fluid mx-auto" alt="..." />
                                <p className="small">Step-by-step directions customized to each patient's journey</p>
                            </div>
                            <div className="col pat-journey text-center" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
                                <img src={PatientJouney05} className="img-fluid mx-auto" alt="..." />
                                <p className="small">Visual cues plus directions make it easy to navigate the path</p>
                            </div>
                        </div>
                    </div>




                    {/* Carousel only visible on small screens */}
                    <div id="carousel_patientJourney" className="carousel carousel-dark slide d-md-none d-lg-none" data-bs-interval="false" data-bs-touch="true">
                        <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carousel_patientJourney" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carousel_patientJourney" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carousel_patientJourney" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carousel_patientJourney" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#carousel_patientJourney" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="10000">
                            <img src={PatientJouney01} className="img-fluid mx-auto d-block" alt="..." />
                            <div className="carousel-caption">
                            <p>Start at home with appointment reminders.</p>
                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={PatientJouney02} className="img-fluid mx-auto d-block" alt="..." />
                            <div className="carousel-caption">
                            <p>Personalized parking &#38; entrance options chosen by the patient</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={PatientJouney03} className="img-fluid mx-auto d-block" alt="..." />
                            <div className="carousel-caption">
                            <p>Physical signage available corresponds to key POIs</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={PatientJouney04} className="img-fluid mx-auto d-block" alt="..." />
                            <div className="carousel-caption">
                            <p>Step-by-step directions customized to each patient's journey</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={PatientJouney05} className="img-fluid mx-auto d-block" alt="..." />
                            <div className="carousel-caption">
                            <p>Visual cues plus directions make it easy to navigate the path</p>
                            </div>
                        </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel_patientJourney" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel_patientJourney" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>


            </section>



            {/* Section: Features */}
            <section className="page-section bg-dark text-white clearfix" id="features">

                <div className="container">

                    <h2 className="text-center mt-0">Product Features</h2>
                    <p className="text-center">Unparalleled wayfinding performance, scalability, and reliability.</p>
                    <hr className="divider" />

                    <div className="row gx-4 gx-lg-5">

                        <div className="col-lg-3 col-md-3 text-center" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="mt-5">
                                <div className="mb-2"><img src={IconFeaturesEmpathetic} alt="Empathetic" width="150" height="150" className="rounded-circle" /></div>
                                <h3>Empathetic</h3>
                                <p>Eyedog centers the user, providing clear photo directions from their unique perspective.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-3 text-center" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="mt-5">
                                <div className="mb-2"><img src={IconFeaturesEffective} alt="Effective" width="150" height="150" className="rounded-circle" /></div>
                                <h3>Effective</h3>
                                <p>97% of users find their way to the destination, reporting a positive experience.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 text-center" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="mt-5">
                                <div className="mb-2"><img src={IconFeaturesAgile} alt="Agile" width="150" height="150" className="rounded-circle" /></div>
                                <h3>Agile</h3>
                                <p>Our WaaS offering provides on-going support ensuring our wayfinding always fits the campus' unique and changing needs.</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 text-center" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="mt-5">
                                <div className="mb-2"><img src={IconFeaturesAdoptable} alt="Agile" width="150" height="150" className="rounded-circle" /></div>
                                <h3>Adoptable</h3>
                                <p>Familiar smartphone technology makes Eyedog easy to use - Everyone can use Eyedog!</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="mt-4 text-center" data-aos="fade-up" data-aos-once="true">
                    <h4>Eyedog. Wayfinding the Right Way.</h4>
                    {/* <a className="btn btn-outline-primary btn-lg mt-4" data-bs-toggle="modal" data-bs-target="#modal_demo">Request a Demo</a> */}
                    <Link className="btn btn-outline-primary btn-lg mt-4" to="/meetnow">Schedule your Demo</Link>
                </div>

            </section>



            {/* Video */}
            <section className="page-section bg-primary clearfix" id="eyedog-video">
            
                <div className="container">

                    <div className="row">
                        <div className="col-md-6">
                            <div className="embed-responsive embed-responsive-16by9" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
                                <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/ZrmS2Q0aGvM" allowfullscreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex flex-column justify-content-center">

                            <h2 className="text-white m-2">See the Eyedog Wayfinding Experience in action!</h2>
                            <p className="text-white m-2">Our Photo Landmark Navigation uses photographs of key points along the path to deliver clear indoor pedestrian directions that match the users’ surroundings. Watch this video to see how Eyedog can transform the Patient Journey from Home to Appointment!</p>

                        </div>
                    </div>

                </div>
            </section>


            {/* Section: Why It Works */}
            <section className="page-section section-keybenefits clearfix" id="whyitworks">

                <div className="container">
                    <h2 className="text-center mt-0">Mobile Wayfinding Technology that works. Every step of the way.</h2>
                    <hr className="divider" />

                    <div className="row">
                        <div className="col-lg-7">

                            <div className="accordion accordion-flush bg-transparent" id="accordion_whyitworks">

                                <div className="accordion-item bg-transparent" data-aos="fade-right" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">
                                    <h2 className="accordion-header" id="flush_scientifically_proven_header">
                                    <button className="accordion-button collapsed bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush_scientifically_proven_body" aria-expanded="false" aria-controls="flush_scientifically_proven_body">
                                        <div className="d-flex align-items-center">
                                            <img src={IconScientificallyProven} alt="Scientifically Proven" width="60" height="60" />
                                            <div className="d-flex align-items-center">
                                                <h4 className="my-0 py-0 align-middle">Scientifically Proven</h4>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="flush_scientifically_proven_body" className="accordion-collapse collapse" aria-labelledby="flush_scientifically_proven_header" data-bs-parent="#accordion_whyitworks">
                                    <div className="accordion-body">Photo landmark navigation employs scientific wayfinding best practices and simple universal icons to make it intuitive for any demographic or language group.<br/><a href="#" data-bs-toggle="modal" data-bs-target="#modal_testimonials"><i className="bi bi-chat-left-quote"></i> Scientific Research says...</a></div>
                                    </div>
                                </div>

                                

                                <div className="accordion-item bg-transparent" data-aos="fade-right" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">
                                    <h2 className="accordion-header" id="flush_photos_not_maps_header">
                                    <button className="accordion-button collapsed bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush_photos_not_maps_body" aria-expanded="false" aria-controls="flush_photos_not_maps_body">
                                        <div className="d-flex align-items-center">
                                            <img src={IconPhotosNotMaps} alt="Photos Not Maps" width="60" height="60" />
                                            <div className="d-flex align-items-center">
                                                <h4 className="my-0 py-0 align-middle">Photos Not Maps</h4>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="flush_photos_not_maps_body" className="accordion-collapse collapse" aria-labelledby="flush_photos_not_maps_header" data-bs-parent="#accordion_whyitworks">
                                    <div className="accordion-body">Navigate by swiping from photo to photo. Combining what you see in the environment with simple schematic direction and easy-to-follow text-based instruction is more intuitive than abstracting your location and direction from the plan view of a map.</div>
                                    </div>
                                </div>

                                <div className="accordion-item bg-transparent" data-aos="fade-right" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">
                                <h2 className="accordion-header" id="flush_visualization_header">
                                    <button className="accordion-button collapsed bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush_visualization_body" aria-expanded="false" aria-controls="flush_visualization_body">
                                        <div className="d-flex align-items-center">
                                            <img src={IconVisualization} alt="Visualization not Interpretation" width="60" height="60" />
                                            <div className="d-flex align-items-center">
                                                <h4 className="my-0 py-0 align-middle">Visualization not Interpretation</h4>
                                            </div>
                                        </div>
                                    </button>
                                </h2>
                                <div id="flush_visualization_body" className="accordion-collapse collapse" aria-labelledby="flush_visualization_header" data-bs-parent="#accordion_whyitworks">
                                    <div className="accordion-body">People naturally use landmarks to generate mental maps of their surroundings and remember the navigational instruction set that ties them together.</div>
                                </div>
                                </div>

                                <div className="accordion-item bg-transparent" data-aos="fade-right" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back">
                                    <h2 className="accordion-header" id="flush_reduces_stress_header">
                                    <button className="accordion-button collapsed bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#flush_reduces_stress_body" aria-expanded="false" aria-controls="flush_reduces_stress_body">
                                        <div className="d-flex align-items-center">
                                            <img src={IconReducesStress} alt="Reduces Stress and Anxiety" width="60" height="60" />
                                            <div className="d-flex align-items-center">
                                                <h4 className="my-0 py-0 align-middle">Reduces Stress and Anxiety</h4>
                                            </div>
                                        </div>
                                    </button>
                                    </h2>
                                    <div id="flush_reduces_stress_body" className="accordion-collapse collapse" aria-labelledby="flush_reduces_stress_header" data-bs-parent="#accordion_whyitworks">
                                    <div className="accordion-body">Not only are hospitals notoriously difficult facilities to navigate, but we often visit the hospital under stressful conditions, overwhelmed with negative emotions. Wayfinding is complicated in these circumstances and a very simple and reliable user experience is required.</div>
                                    </div>
                                </div>

                            </div>

                            <div className="my-4 text-center py-3" data-aos="fade-up" data-aos-once="true"><a className="btn btn-outline-primary btn-lg" href="https://publuu.com/flip-book/4453/8602/page/1" target="_blank">See our Brochure</a></div>

                        </div>

                        <div className="col-lg-5"><img src={IllusEyedogAdvantage} alt="Why it Works" className="img-fluid" /></div>
                    </div>

                </div>
            </section>



            {/* Section: Eyedog Advantage */}
            <section className="page-section bg-light clearfix" id="eyedog-advantage">

                <div className="container">

                    <div className="row">
                        <div className="col-lg-7">

                            <h2 className="text-center mt-0">The Eyedog Advantage</h2>
                            <hr className="divider" />

                            <div className="row m-0">
                                <div className="col-lg-6 py-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-once="true">
                                    <h3>Mitigate the Cost of Confusion</h3>
                                    <p>Eyedog wayfinding optimizes patient flow, reducing late and missed appointments, thus leading to monetary benefits and additional ROI.</p>
                                </div>
                                <div className="col-lg-6 py-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-once="true">
                                    <h3>Omni-Channel Availability</h3>
                                    <p>Eyedog navigation is accessible and flexible for absolutely everyone - users don't have to be tech savvy to understand Eyedog!</p>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-lg-6 py-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-once="true">
                                    <h3>Easy to Maintain and Extend</h3>
                                    <p>Eyedog makes updating pathways simple and immediate. Our WaaS offering accounts for building changes and expansion.</p>
                                </div>
                                <div className="col-lg-6 py-2"  data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-once="true">
                                    <h3>Affordable &#38; Scalable</h3>
                                    <p>No beacons, hardware, or network infrastructure required - and no need for constant internet connectivity.</p>
                                </div>
                            </div>
                            <p className="text-center mb-4">
                                <Link className="btn btn-outline-primary btn-lg mt-4" to="/meetnow">Schedule your Demo</Link>
                            </p>
                        </div>
                        <div className="col-lg-5"><img src={IllusEyedogAdvantage2} alt="The Eyedog Advantage" className="img-fluid" /></div>

                    </div>

                </div>
            </section>


            {/* Section: Team */}
            <section className="page-section clearfix" id="team">
                <div className="container px-4 px-lg-5">
                    <h2 className="text-center mt-0">The Eyedog.US Team</h2>
                    <hr className="divider" />
                    <p>Behind Eyedog.US is a team of passionate individuals who strive to bring compassion and empathy into the patient experience
                        in a variety of healthcare settings. Because we know that Healthcare facilities often foster intense emotion - both good
                        and bad - we center our work in developing compassionate solutions meant to ease stress and anxiety during some of life's
                        most intense emotional moments. Our tech solutions are paired with an empathetic approach that improves the human experience.</p>
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-lg-4 col-md-4 flex-column d-flex text-center mt-4" data-aos="zoom-in-up" data-aos-once="true">

                            <div className="my-2"><img src={KayVia} alt="Kay Via" width="150" className="rounded-circle" /></div>
                            <h3>Kay Via</h3>
                            <p><strong>VP Operations and Sales</strong></p>
                            <p className="text-muted mb-0">Kay is passionate about the patient experience and knows firsthand the major impact good digital wayfinding makes on a healthcare campus. With Eyedog.US, she provides executive leadership in the areas of sales, client implementations, and adoption services.</p>
                            <div className="text-center p-3" style={{marginTop: 'auto'}}>
                                <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/kayvia/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                <a className="btn btn-dark btn-social mx-2" href="https://twitter.com/kay_via" target="_blank"><i className="bi bi-twitter"></i></a>
                            </div>

                        </div>
                        <div className="col-lg-4 col-md-4 flex-column d-flex text-center mt-4" data-aos="zoom-in-down" data-aos-once="true">

                            <div className="my-2"><img src={JeffFisher} alt="Jeff Fisher" width="150" className="rounded-circle" /></div>
                            <h3>Jeff Fisher</h3>
                            <p><strong>Board Member and Advisor</strong></p>
                            <p className="text-muted mb-0">Jeff looks at the whole picture to imagine creative ways to engage healthcare communities in digital wayfinding technology that promises a positive patient experience. With Eyedog.US, Jeff provides technical leadership, strategy, and patient engagement strategy services.</p>
                            <div className="text-center p-3" style={{marginTop: 'auto'}}>
                                <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/jeff-fisher-b785042/" target="_blank"><i className="bi bi-linkedin"></i></a>
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-4 flex-column d-flex text-center mt-4" data-aos="zoom-in-up" data-aos-once="true">

                            <div className="my-2"><img src={JohnSkinner} alt="John Skinner" width="150" className="rounded-circle" /></div>
                            <h3>John Skinner</h3>
                            <p><strong>Board Member and Advisor</strong></p>
                            <p className="text-muted mb-0">With his finger on the pulse of what's new in both healthcare and digital wayfinding, John understands the needs of healthcare communities, nation-wide.  With Eyedog.US, John provides executive leadership in the areas of sales, marketing, and consultancy services.</p>
                            <div className="text-center p-3" style={{marginTop: 'auto'}}>
                                <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/john-skinner-361272/" target="_blank"><i className="bi bi-linkedin"></i></a>
                            </div>

                        </div>
                    </div>
                    
                    <div className="mt-4 text-center" data-aos="fade-up" data-aos-once="true">
                        <a className="btn btn-outline-primary btn-lg mt-4" data-bs-toggle="modal" data-bs-target="#modal_contact">Contact Us</a>
                    </div>
                    
                </div>
            </section>


            {/* Section: Blog */}
            <section className="page-section bg-light clearfix" id="newspress">
                <div className="container">
                    <h2 className="text-center mt-0">News &amp; Updates at Eyedog.US</h2>
                    <p className="text-center">Stay up to date with industry content, wayfinding features, and helpful resources.</p>
                    <p className="text-center"><a href="#" data-bs-target="#modal_subscribe" data-bs-toggle="modal" className="btn btn-outline-primary btn-sm rounded-3" title="Subscribe to our newsletter"><i class="bi bi-newspaper"></i> Subscribe to our newsletter</a></p>
                    <hr className="divider" />
                    <Blog posts={props.toddPressData} postsPerPage={5}/>
                </div>
            </section>

            {/* Section: Case Study */}
            <section className="page-section bg-primary clearfix" id="casestudy">
                <div className="container">
                    <h2 className="text-center text-white mt-0">Case Study: Eyedog.US Digital Wayfinding at Vail Health Hospital</h2>
                    <p className="text-center text-white">How Does Creative Digital Wayfinding Lead to an Improved Patient Experience?</p>
                    <hr className="divider bg-white" />
                    <p className="text-white">Indoor navigation of complex healthcare facilities is a common but complex problem, often overlooked. In a joint case study, Eyedog.US and Vail Health examine the ways in which Eyedog Photo Landmark Navigation solves those unique and difficult wayfinding challenges. We invite you to learn more about how our creative and intelligent digital wayfinding solution solves complex challenges in the healthcare wayfinding setting.</p>
                    <p class="text-center" data-aos="fade-up" data-aos-duration="500" data-aos-once="true" data-aos-easing="ease-out-back"><a href="https://publuu.com/flip-book/4453/224765/page/1" class="btn btn-outline-light btn-lg mt-3" target="_blank" rel="noopener noreferrer">Read the full Case Study</a></p>
                </div>
            </section>


            {/* Testimonials Modal */}
            <div className="modal fade" id="modal_testimonials" tabindex="-1">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Don't take our word for it, take theirs.</h5>			  
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                    <div className="row row-cols-1">

                        <div className="col d-flex align-items-start mt-3 mb-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-once="true">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                            <img src={QuotationIcon} alt="Go-live in Weeks not Months" width="50" height="50"/>
                            </div>
                            <div>
                            <p><em>Landmarks are ideal wayfinding tools for directing a person from A to B as they allow fast reasoning and efficient communication.</em></p>
                            <p className="small text-end"><strong>Stephan Winter, PhD</strong>
                            <br/>Department of Spatial Information Science,
                            <br/>University of Melbourne, Australia</p>
                            <p className="small text-end">
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <br/><span className="small text-muted">Efficient communications platform</span>
                            </p>
                            </div>
                        </div>

                        <div className="col d-flex align-items-start mt-3 mb-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-once="true">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                            <img src={QuotationIcon} alt="Go-live in Weeks not Months" width="50" height="50"/>
                            </div>
                            <div>
                            <p><em>One of the most important elements wayfinding best practices acknowledges, is that most orientation occurs via landmark for any building user.</em></p>
                            <p className="small text-end"><strong>Philippe De Maeyer, PhD</strong>
                            <br/>Department of Geography,
                            <br/>Ghent University, Belgium</p>
                            <p className="small text-end">
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <br/><span className="small text-muted">Landmarks are better for indoor navigation</span>
                            </p>
                            </div>
                        </div>

                        <div className="col d-flex align-items-start mt-3 mb-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="0" data-aos-once="true">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                            <img src={QuotationIcon} alt="Go-live in Weeks not Months" width="50" height="50"/>
                            </div>
                            <div>
                            <p><em>The use of landmarks is often linked with the quality of route instructions as they are related to the natural cognitive navigation process of humans.</em></p>
                            <p className="small text-end"><strong>Daniel R. Montello, PhD</strong>
                            <br/>Department of Geography,
                            <br/>UC Santa Barbara</p>
                            <p className="small text-end">
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <br/><span className="small text-muted">Aligned with how humans think</span>
                            </p>
                            </div>
                        </div>

                        <div className="col d-flex align-items-start mt-3 mb-2" data-aos="zoom-in" data-aos-duration="500" data-aos-delay="200" data-aos-once="true">
                            <div className="icon-square text-dark flex-shrink-0 me-3">
                            <img src={QuotationIcon} alt="Go-live in Weeks not Months" width="50" height="50"/>
                            </div>
                            <div>
                            <p><em>Many people have difficulties interpreting maps and other abstract visualizations of space. Research acknowledges that indoor maps are a compromised interface for people finding their way in complex environments.</em></p>
                            <p className="small text-end"><strong>Christoph H&ouml;lscher, PhD</strong>
                            <br/>Department of Cognitive Science,
                            <br/>ETH Z&uuml;rich, Switzerland</p>
                            <p className="small text-end">
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <i className="bi bi-star-fill text-primary"></i>
                                <br/><span className="small text-muted">Say no to maps and yes to photos</span>
                            </p>
                            </div>
                        </div>

                    </div>


                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
                </div>
            </div>  


        </>
    );
}

export default Home;
